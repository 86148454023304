.actionButton {
  display: block;
  font-size: 0.7em;
  padding: 0.5em 1em;
}

.actionCell {
  display: flex;
  justify-content: flex-end;
}

.col1 {
  width: 80px !important;
}

.notFound {
  margin: 1em 0;
}

.paginator {
  margin: 2em 0 0;
}

.tableTitle {
  font-weight: bold;
  font-size: x-large;
}

.tdName,
.tr {
  cursor: pointer;
}

.artisanName {
  display: flex;
  align-items: center;
}
.artisanName span {
  margin-left: 8px;
}

@media screen and (min-width: 700px) and (max-width: 991px) {
  .actionButton1 {
    margin: 0 1em 0 0;
  }

  .actionButtonWrap {
    display: flex;
  }
}

@media screen and (min-width: 800px) and (max-width: 991px) {
  .actionButton {
    font-size: 0.75em;
    padding: 0.84em 1.66em;
  }
}

@media screen and (min-width: 1250px) {
  .actionButton1 {
    margin: 0 1em 0 0;
  }

  .actionButtonWrap {
    display: flex;
  }
}
