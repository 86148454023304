.additionalFields {
  padding-top: 3em;
  padding-bottom: 1em;
  position: relative;
}

.additionalFields:before {
  border-top: 1px solid #d8d8d8;
  content: '';
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
}

.ddCountryRegion {
  background: #fff;
  border-color: var(--main-bg-color);
}

.input[type='text'],
.inputWrap input,
.select,
.input[type='email'],
.input[type='number'],
.textarea,
.multipleSelect select {
  display: inline-block;
  padding: 12px 16px;
  border: 1px solid var(--main-bg-color) !important;
  box-sizing: border-box;
  color: black;
  min-height: 50px;
  background-color: white;
  font-size: inherit;
}

.input[type='text'],
.input,
.select,
.input[type='email'],
.input[type='number'],
.textarea,
.multipleSelect,
.radioWrapper {
  width: 60%;
}

.multipleSelect select option {
  margin-bottom: 3px;
}

.select {
  padding-left: 14px;
}

.input::placeholder,
.inputWrap input::placeholder {
  color: #ccc;
  letter-spacing: normal;
}

.button {
  width: 40%;
  height: 50px;
}
.button[disabled] {
  cursor: auto;
}

.multipleSelect.fixedPriceList > select {
  height: 5.4em;
}

.form {
  margin-bottom: 1em;
  margin-top: 4em;
}

.input[type='submit'] {
  width: 40%;
  color: #fff;
  padding: 14px 20px;
  margin: 15px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input[type='submit']:hover {
  background-color: var(--main-04-color);
}

.inputWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 2em;
  flex-wrap: wrap;
}
.inputWrap.alignRight {
  justify-content: flex-end;
}

.uploadWrap {
  margin-bottom: 2em;
}

.uploadWrap ul {
  margin-bottom: 1em;
}

.uploadWrap li {
  display: flex;
  align-items: center;
}

.uploadWrap a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uploadWrap button {
  background-color: transparent;
  border: 0;
}

.label {
  margin: 0;
  text-align: right;
  width: 35%;
}

.textarea {
  /* height: 150px; */
  border-color: var(--main-bg-color);
  /* padding: 20px; */
  box-sizing: border-box;
}

.input:focus,
.textarea:focus {
  background-color: var(--main-04-color);
}

.checkboxs {
  text-align: left;
  width: 60%;
  display: inline-block;
}

.sameLineCheckboxs {
  display: flex;
}

.sameLineCheckboxs > div:not(:first-child) {
  margin-left: 20px;
}

.check {
  width: 100%;
}

.check p {
  vertical-align: top;
  width: 40%;
  display: inline-block;
  margin: 0;
}

.areaInput {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.error {
  color: red;
  padding: 0;
  text-transform: uppercase;
  width: 60%;
  font-size: 0.6875em;
  font-weight: 500;
  margin-top: 5px;
  /* text-align: right; */
  margin-left: auto;
}

.wrap select,
.wrap .input,
.wrap .textarea,
.wrap :global(.custom-checkbox .custom-control-label::before) {
  border-radius: 0;
}

.wrap :global(.custom-checkbox .custom-control-label::before),
.wrap :global(.custom-radio .custom-control-label::before) {
  border-color: var(--main-bg-color);
}

.wrap
  :global(.custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after),
.wrap
  :global(.custom-radio
    .custom-control-input:checked
    ~ .custom-control-label::after) {
  background-position: 50% 0;
}

.date {
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.1125em;
}

.date .dateLabel {
  font-weight: 500;
}

.date:not(:last-child) {
  margin-bottom: 1em;
}

.date:last-child {
  margin-bottom: 2em;
}

.vendorManagement {
  border-top: 1px solid #d8d8d8;
  margin-top: 2em;
  padding: 1em 0;
  text-align: left;
}

.vendorManagement > button {
  margin-right: 0.5em;
}
